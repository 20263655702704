<template>
  <transition :name="`slide-${transition}`">
    <component :is="content" />
  </transition>
</template>

<script>
import useQuickscreen from "../../composables/useQuickscreen";
import { defineAsyncComponent, computed } from "vue";
export default {
  setup() {
    const { currentStep, transition } = useQuickscreen();
    const content = computed(() => {
      if (currentStep.value) {
        return defineAsyncComponent(() =>
          import(
            `../../components/screen/steps/contact/${currentStep.value.component}.vue`
          )
        );
      }
      return null;
    });
    return {
      content,
      transition,
    };
  },
};
</script>

<style></style>
